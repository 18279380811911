/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.rbc-today {
  background-color: transparent !important;
}

.rbc-toolbar {
  margin-bottom: 0;
  border-radius: 12px 12px 0px 0px;
  background: #fff !important;
  flex-direction: row !important;
}

.rbc-event {
  padding: 0px 0px !important
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}

.react-calendar {
  border-radius: 12px;
  box-shadow: 0 0 70px -40px #00000078 !important;
  border: 1px solid #eee !important;
}

.agenda-table {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 70px -40px #00000078 !important;
  border: 1px solid #eee !important;
}

.agenda-table .MuiPaper-elevation4 {
  box-shadow: none !important;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.custom-timeline-list-label {
  font-size: 1rem;
  margin: 0;
  color: #070919;
  display: flex;
  align-content: center;
  align-items: center;
  line-height: 1.5;
}

.w-20 {
  width: 20%;
}

.w-85 {
  width: 85%;
}

.custom-timeline-list-icon {
  background: #5383ff;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 20px;
  display: block;
  border-radius: 20px;
}

.custom-dropzone {
  min-height: 125px !important;
  width: 50% !important;
  margin: 0 auto !important;
}

.crop {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.tree-error .react-dropdown-tree-select .dropdown .dropdown-trigger {
  border: 1px solid red !important;
}

.react-dropdown-tree-select.billing-tree-select .dropdown .dropdown-content {
  max-height: 300px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
  max-height: 500px !important;
  overflow: hidden !important;
  overflow-y: scroll !important;
}

input#root_numberOfKilometers {
  color: rgba(0, 0, 0, 0.54);
}

#root_numberOfKilometers.number-of-kilometers-changed {
  color: rgba(0, 0, 0, 0.87);
}

.carDetailsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-left: 30px;
}

.carDetailsField {
  display: flex;
  border: 1px solid;
  min-width: 123px;
  box-sizing: border-box;
  padding: 16px 14px;
  font-size: 14px;
  text-align: center;
  border-radius: 0.5rem;
}

.carDetailsGrid .carDetailsField {
  height: auto;
}

.carDetailsGrid .carDetailsField {
  width: minmax(auto, 1fr);
  height: minmax(auto, 1fr);
}

.appointmentTable .rbc-toolbar {
  display: none;
}

@media (min-width: 1650px) {
  .appointmentClientSelectionDetails {
    width: 70%;
  }
}

@media (max-width: 1650px) {
  .appointmentClientSelectionDetails {
    width: 100%;
  }
}

@media (min-width: 1000px) {
  .tableActions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start
  }
}

@media (max-width: 1000px) {
  .tableActions {
    display: flex;
    flex-direction: column;
  }
}

.appointmentDetailsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 90%;
}

@media (min-width: 1000px) {
  .appointmentDetailsContainer {
    gap: calc(8%)
  }
}

@media (max-width: 1000px) {
  .appointmentDetailsContainer {
    gap: calc(2%)
  }
}

.appointmentDetailsField {
  display: flex;
  font-size: 14px;
  text-align: center;
}

.appointmentDetailsContainer .appointmentDetailsField {
  height: 100%;
}

.appointmentDetailsContainer .appointmentDetailsField {
  width: minmax(auto, 1fr);
  height: minmax(auto, 1fr);
}


.reschedule-calendar-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 10px;
  overflow-x: auto;
  width: 95%;
  height: 90%;
  cursor: pointer;
}

.bigCalendar .rbc-event-label {
  display: none;
}

.custom-outline {
  border: 2px solid rgb(27, 110, 194) !important;

}

.appointmentRepairDetailsContainer {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

}

@media (min-width: 800px) {
  .appointmentRepairDetailsContainer {
    gap: calc(20%);
    width: 80%;
  }
}

@media (max-width: 800px) {
  .appointmentRepairDetailsContainer {
    gap: calc(3%);
    width: 100%;
  }
}

.custom-button {
  border: 1px solid black !important;
  /* Override MUI default styles */
  color: black !important;
  /* Set text color to black */
}

.custom-button:hover {
  border: 1px solid black;
  /* Maintain border color on hover */
  color: black !important;
  /* Ensure text color remains black on hover */
  background-color: rgba(0, 0, 0, 0.1);
  /* Optional: light background on hover */
}


.rbc-overlay {
  z-index: 100000 !important;
}

.calendar-text-field:hover {
  cursor: pointer !important;
}

.calendar-text-field .MuiOutlinedInput-input:hover {
  cursor: pointer;
}

.calendar-text-field .MuiOutlinedInput-input:focus {
  cursor: auto;
}

.id-calendar.Mui-error .MuiOutlinedInput-notchedOutline,
.id-hour.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.checkbox-no-padding {
  padding: 0 !important;
}

.checkbox-no-top-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.green-text {
  color: green !important;
}

.red-text {
  color: red !important;
}

.default-text {
  color: inherit;
}

.navigateToGalleryButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1F2857;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  font-size: 30px;
}

.zoomGalleryButton {
  background-color: #1F2857;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
}

.red-text {
  color: red !important;
}

.green-text {
  color: green !important;
}
