// Vehicle Import Modal

.search-vehicle-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
    background-color: white;
    padding: 10px
}