// Core

@import "core";

// Fonts

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600&display=swap');


// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";

  
@include media-breakpoint-down(xs)  {
    *, *::before, *::after {
        box-sizing: inherit;
    }
    html {
        /* this makes sure the padding and the border is included in the box sizing */
        box-sizing: border-box;
        overflow-x: hidden;
    }
      
    html body {
        padding: 0;
        margin: 0;
        overflow-x: hidden;
    }

    .calculation-button{ 
        width: 50%;
    }

    .tab-forms [role="tablist"]{
       flex-direction: column;
    }

    .MuiTabs-indicator {
        display: none;
    }

    .attachment-container {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .attachment-container-button {
        max-width: 100% !important;
        flex-basis: 100% !important;
        align-items: end !important;
    }
    
    .car-details-form {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    
}