// Vehicle Import Modal

.vehicle-import-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    background-color: white;
    padding: 10px
}